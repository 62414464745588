import React, {useState, useEffect, useRef, useMemo} from 'react';
import { StaticImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import Seo from "../components/seo";
import Layout from "../components/layout";
import Header from "../components/header";
import Video from "../components/videoCards";
import Tavern from '../video/tavern2.mp4';
import Gameplay from '../video/gameplay_noaudio.mp4';
import FlipCard from "../components/flipCard";
import Faq from "react-faq-component";
import "../styles/faq.scss";

import "swiper/css";
import "swiper/css/effect-cards";
import 'swiper/css/pagination';
import "../styles/cards.scss";
import { EffectCards, Pagination } from "swiper";


// markup
const EtheriaPage = () => {

  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);

  useEffect(() => {
    const zoom = elementRef.current.getBoundingClientRect().width / 1500;
    setWidth(zoom);
  }, []);

  const [rows, setRowsOption] = useState(null);
  const config = {
    animate: true,
    arrowIcon: "V",
    openOnload: 0,
    expandIcon: "+",
    collapseIcon: "-",
    openOnload: false,
  };
  const data = {
    rows: [
      {
        title: "Is there a Discord?",
        content:
          "Yes, you can join our community <a style='color:#DCC38A; text-decoration:underline' href='https://discord.gg/KRqbAKdcXY'>HERE</a> to get up to date information on minting details, community events, sneak peaks, roadmap updates, and more."
      },
      {
        title: "What is Origin Heroes?",
        content:
          "Origin Heroes is a first-of-its-kind play-to-earn strategy game set in a mysterious and conflict-ridden metaverse known as Etheria. Players use a merge-based ecosystem to battle, quest, craft, and socialize, all while earning Elixir of Courage (EOC)."
      },
      {
        title: "What is the First Heroes Collective?",
        content:
        "A limited collection of 8,888 Origin Hero NFTs that feature a unique and diverse pool of attributes. Each hero will be available for individual ownership and doubles as your in-game avatar, allowing you to play and earn Elixir of Courage.<br/><br/>Furthermore, ownership of a First Collective Hero is your key to ongoing utility-driven benefits. Holders can expect to receive exclusive access to land plots, future drops, merch, collaborations, and more."
      },
      {
        title: "How are the 8,888 Origin Heroes distributed?",
        content:
        "8,888 total supply<br/>8,333 available for public sale<br/>300 used for growth and marketing initiatives<br/>200 held for future collaborations, partnerships and advisors<br/>55 allocated to team members"
      },
      {
        title: "Wen Mint?",
        content:
          "October 31st, 12:00 noon EST, presale for whitelist <br/>November 2nd, 12:00 noon EST, public sale"
      },
      {
        title: "What is the mint price?",
        content:
        "Presale price - 0.08 ETH<br/>Public sale price - 0.1 ETH"
      },
      {
        title: "How does the mint work?",
        content:
        "Full details on the mint process are posted in our Discord server."
      },
      {
        title: "Is there a whitelist?",
        content:
        "Yes, details on how to get on the whitelist will be communicated in our Discord server."
      },
      {
        title: "Will Heroes be revealed right away?",
        content:
        "Heroes will be revealed within a few days of the mint."
      },
    ]
  };

  const data2 = {
    rows: [
      {
        title: "What perks do First Heroes Collective holders get?",
        content:
        "The First Heroes Collective holders get access to the game. Each Hero doubles as your in-game character used to play, earn and create new generations of heroes within the world of Etheria.<br/><br/>Additionally, Holders can expect to receive exclusive access to land plots, future drops, merch, IRL events, collaborations, and more."
      },
      {
        title: "What is Origin Land?",
        content:
        "Origin Land is a digital plot in the world of Etheria with individual ownership that can be built upon. Each Origin Land will be unique and evolve as the metaverse develops."
      },
      {
        title: "How will Origin Land be distributed?",
        content:
        "Each First Hero Collective holder will be able to claim an Origin Land for free (regular gas fees apply). The remaining Origin Lands will be available for purchase to the public."
      },
      {
        title: "What do you get with an Origin Land?",
        content:
        "Owning Origin Land in the world of Etheria allows owners to engage with gameplay, but it can also be built upon and customized over time."
      },
      {
        title: "What does gameplay look like?",
        content:
        "The gameplay consists of a first-of-its-kind merge strategy game that takes place on a merge board. The merge board quickly fills with items that are then merged to form higher-level and/or unique items.<br/><br/>These items are then used to interact with the world of Etheria by completing quests, crafting, battling, upgrading your Origin Heroes, etc."
      },
      {
        title: "What platforms will Origin Heroes be available on?",
        content:
        "Origin Heroes will be available on the desktop first. A mobile version will be available later on."
      },
      {
        title: "What is Elixir of Courage?",
        content:
        "Elixir of Courage (EOC) is a utility token that is earned while playing the game. EOC is the premium currency of Etheria that allows players to imbue new generations of Heroes, build on land plots, acquire rare crafting materials, govern society, and more."
      },
      {
        title: "What do I use Elixir of Courage for?",
        content:
        "Elixir of Courage or EOC is used to create new generations of Heroes to join your cause.<br/>Additionally, Elixir of Courage is an everlasting commodity that will be used at later stages to build on land plots, acquire rare crafting materials, govern society, and more"
      },
      {
        title: "Where will secondary sales be for the First Collective Heroes Collective?",
        content:
        "Yea, the First Heroes Collective will be listed on OpenSea and LooksRare."
      },
    ]
  };

  const roadmap = [
    {
      id: 1,
      quarter: 'q3',
      year: '2022',
      list:[
          'Game Development Begins',
          'Website and Discord Launch',
          'Infrastructure and Community Building',
          'Concept Art and Game Trailer'
      ]
    },
    {
      id: 2,
      quarter: 'q4',
      year: '2022',
      list:[
        'Launch of the First Heroes Collective NFT Collection',
        'Story Lore and Map Unveiled',
        'Integration on Rarity Platforms',
      ]
    },
    {
      id: 3,
      quarter: 'H1',
      year: '2023',
      list:[
        'Holder Exclusive Merch Drop',
        'Closed Testing Begins',
        'Origin Land Plots Minted',
        'More to be announced!'
      ]
    },
    {
      id: 4,
      quarter: 'H2',
      year: '2023',
      list:[
        'Alpha Version Release',
        '$EOC Utility Token Launch',
        'Imbuing System Activated',
        'Gen 2 Heroes Release',
        'More to be announced!'
      ]
    }
  ]
  const coins = [
    {
      id: "1",
      top: '20%',
      left: '20%',
      rotate: '0deg'
    },
    {
      id: "2",
      top: '25%',
      left: '70%',
      rotate: '90deg'
    },
    {
      id: "3",
      top: '60%',
      left: '95%',
      rotate: '70deg'
    },
    {
      id: "4",
      top: '85%',
      left: '50%',
      rotate: '15deg'
    },
    {
      id: "5",
      top: '60%',
      left: '0%',
      rotate: '20deg'
    }
  ]

  const teams = [
    {
      id: '1',
      name: 'Metalovin',
      title: 'Hero in Charge',
      descr: 'Co-founder of Tastypill Games. Game design guru.',
      linkedin: 'https://www.linkedin.com/in/phillip-kung-b7ab5a128/',
      twitter: 'https://twitter.com/metalovin',
      img: '../images/animated/Phillip.mp4',
      ogg: '../images/animated/Phillip.ogg',
      webm: '../images/animated/Phillip.webm',
      poster: '../images/animated/Phillip.jpg'
    },
    {
      id: '2',
      name: 'LordSandwich',
      title: 'Head of Growth',
      descr: 'Founder of Udonis. Marketing wizard.',
      linkedin: 'https://www.linkedin.com/in/mgrguric/',
      twitter: 'https://twitter.com/LordSandwichOne',
      img: '../images/animated/Mike.mp4',
      ogg: '../images/animated/Mike.ogg',
      webm: '../images/animated/Mike.webm',
      poster: '../images/animated/Mike.jpg'
    },
    {
      id: '3',
      name: 'PickleRick',
      title: 'Operations',
      descr: 'Creative at heart, realistic in practice. Runs ops.',
      linkedin: 'https://www.linkedin.com/in/jure-zelenika-2320a3171/',
      twitter: 'https://twitter.com/PickleRickEth',
      img: '../images/animated/Jure.mp4',
      ogg: '../images/animated/Jure.ogg',
      webm: '../images/animated/Jure.webm',
      poster: '../images/animated/Jure.jpg'
    },
    {
      id: '4',
      name: 'Miggy',
      title: 'Community Alpha',
      descr: 'Crypto degen from 2011, community advocate & your favorite influencer',
      linkedin: 'https://www.linkedin.com/in/miggycrypto/',
      twitter: 'https://twitter.com/Crypto33Miggy',
      img: '../images/animated/Miggy.mp4',
      ogg: '../images/animated/Miggy.ogg',
      webm: '../images/animated/Miggy.webm',
      poster: '../images/animated/Miggy.jpg'
    },
    {
      id: '5',
      name: 'NFTone',
      title: 'Lead Developer',
      descr: '.ɐןınbǝʇ ɟo ʇoɥs ɐ puɐ uoɯǝן ɟo ǝɔıןs ɐ ,ʇןɐs ɟo uıɐɹƃ ɐ ɥʇıʍ sʇɟu sǝʞɐʇ sʎɐʍןɐ',
      linkedin: '#',
      twitter: '#',
      img: '../images/animated/Gabriel.mp4',
      ogg: '../images/animated/Gabriel.ogg',
      webm: '../images/animated/Gabriel.webm',
      poster: '../images/animated/Gabriel.jpg'
    },
    {
      id: '6',
      name: 'SpencerZ',
      title: 'Lead Designer',
      descr: 'Creates cool art and stuff. True geek at heart.',
      linkedin: '',
      twitter: '#',
      img: '../images/animated/Fajar.mp4',
      ogg: '../images/animated/Fajar.ogg',
      webm: '../images/animated/Fajar.webm',
      poster: '../images/animated/Fajar.jpg'
    },
    {
      id: '7',
      name: 'Double Ape',
      title: 'Marketing & Vibes',
      descr: 'This guy does Spaces in his sleep.',
      linkedin: '',
      twitter: 'https://twitter.com/doubleape',
      img: '../images/animated/Aaron.mp4',
      ogg: '../images/animated/Aaron.ogg',
      webm: '../images/animated/Aaron.webm',
      poster: '../images/animated/Aaron.jpg'
    },
    {
      id: '8',
      name: 'Caldie',
      title: 'Advisor',
      descr: 'Mobile gaming master turned NFT degen. Founder of WASD.',
      linkedin: 'https://www.linkedin.com/in/caldie/',
      twitter: 'https://twitter.com/caldie',
      img: '../images/animated/Christian.mp4',
      ogg: '../images/animated/Christian.ogg',
      webm: '../images/animated/Christian.webm',
      poster: '../images/animated/Christian.jpg'
    },
  ]

  const cards = [
    {
      id: 1,
      icon: '/images/card1.svg',
      title: 'Play and Earn',
      text: 'Players will earn utility tokens in the form of Elixir of Courage ($EOC) from playing the game. These tokens are used to create new generations of tradeable NFT Heroes.'
    },
    {
      id: 2,
      icon: '/images/card2.svg',
      title: 'Community Driven',
      text: 'First Heroes Collective holders will be able to contribute creatively and control the developing storyline of Etheria which will directly impact future expansions and events.'
    },
    {
      id: 3,
      icon: '/images/card3.svg',
      title: 'Land Ownership',
      text: 'Origin Land will become available later this year and grants individual ownership over plots of land in the world of Etheria.'
    }
  ];

  return (
    <Layout classmain="eth container">
      <Seo
        title='Welcome to Etheria'
        bodyclass='eth-container'
        metaDesciption='A limited NFT collection of unique and diverse utility-powered Heroes. Each Hero doubles as your gateway to exploring the fantastical world of Etheria.'
      />
        <Header/>
        <div className='hero' ref={elementRef}>
          <div className='hero__tavern-container'>
              <div>
                    <video
                      loop
                      muted
                      autoPlay
                      playsInline
                      preload="metadata"
                      id="ehVideo"
                      poster="/images/tavern.jpg"
                      className="hero__tavern"
                    >
                      <source src={Tavern} type="video/mp4" />
                    </video>
              </div>
          </div>
          <StaticImage
                alt='Origin Heroes'
                className="hero__tavern--chr"
                src='../images/hero-one.png'
          />
          <div className='hero__down' onClick={()=> window.open("#what", "_self")} role="button">
            <div className="go down"></div>
            <img className='hero__down--arrow' src="/images/down.svg" alt=""/>
          </div>
        </div>
        
        <div className='what relative' id="what">
          <h1>What is Origin Heroes?</h1>
          <p>
              Origin Heroes is a first-of-its-kind Play and Earn strategy game set in a mysterious and conflict-ridden metaverse known as Etheria. Players use a merge-based ecosystem to battle, quest, craft, and socialize as they explore a strange new land that witnessed the rise of the first Heroes.<br/><br/>
              Heroes earn Elixir of Courage utility tokens as they progress through the game. This is the premium currency of Etheria, and is used to create new generations of Heroes to join your cause. Additionally, Elixir of Courage is an everlasting commodity that will be used at later stages to build on land plots, acquire rare crafting materials, govern society, and more.
          </p>
          <div className='what-out'>
              <div>
                  <video
                    loop
                    muted
                    autoPlay
                    playsInline
                    preload="metadata"
                    id="ethVideo"
                    poster="/images/back-home.jpg"
                    className="what-video"
                    data-sal="zoom-in"
                    data-sal-delay="50"
                    data-sal-easing="ease"
                  >
                    <source src={Gameplay} type="video/mp4" />
                  </video>
              </div>
          </div>
          <StaticImage
                  alt='Origin Heroes'
                  className="what__arm hidemob"
                  src='../images/glove.png'
                  objectFit='contain'
          />
        </div>
        <div className='first' id="collection">
         <div className='fix-relative'>
            <div className='first-container container'>
              <div className='first__row row'>
                <div className='first__left col-md-6 col-12'>
                  <div className='first__left-out'>
                    <h3>
                      The First Heroes Collective</h3>
                    <p>
                      A limited collection of 8,888 Origin Hero NFTs that feature a unique and diverse pool of attributes. The metadata of each Hero is rooted within the story of Etheria – from their class to their kingdom affiliation. Each Hero doubles as your in-game character used to play, earn, and create new generations of heroes.
                     <p> Furthermore, ownership of a First Collective Hero is your key to ongoing utility-driven benefits. Holders can expect exclusive access to land plots, future drops, merch, collaborations, and more.</p>
                    </p>
                    <div className='first--tree-container hidemob'
                    data-sal="slide-right"
                    data-sal-delay="50"
                    data-sal-easing="ease">
                      <StaticImage
                              alt='Origin Heroes'
                              className="first--tree"
                              src='../images/tr.png'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='wall'>
              <div className='wall__row row'>
                <div className='wall__left col-md-4 col-12'>
                </div>
                <div className='wall__right col-md-8 col-12'>
                    <div className='wall__right-container wall--image'
                      data-sal="slide-left"
                      data-sal-delay="50"
                      data-sal-easing="ease">
                        <div className='wall__nft'>
                          <div className='wall__nft-item wall__nft-item--v1'>
                            <StaticImage
                                    alt='Origin Heroes'
                                    className="wall--nft"
                                    src='../images/h1.png'
                            />
                          </div>
                          <div className='wall__nft-item wall__nft-item--v2'>
                            <StaticImage
                                    alt='Origin Heroes'
                                    className="wall--nft"
                                    src='../images/h2.png'
                            />
                          </div>
                          <div className='wall__nft-item wall__nft-item--v3'>
                            <StaticImage
                                    alt='Origin Heroes'
                                    className="wall--nft"
                                    src='../images/h3.png'
                            />
                          </div>
                          <div className='wall__nft-item wall__nft-item--v4'>
                            <StaticImage
                                    alt='Origin Heroes'
                                    className="wall--nft"
                                    src='../images/h4.png'
                            />
                          </div>
                          <div className='wall__nft-item wall__nft-item--v5'>
                            <StaticImage
                                    alt='Origin Heroes'
                                    className="wall--nft"
                                    src='../images/h5.png'
                            />
                          </div>
                          <div className='wall__nft-item wall__nft-item--v6'>
                            <StaticImage
                                    alt='Origin Heroes'
                                    className="wall--nft"
                                    src='../images/h6.png'
                            />
                          </div>
                          <div className='wall__nft-item wall__nft-item--v7'>
                            <StaticImage
                                    alt='Origin Heroes'
                                    className="wall--nft"
                                    src='../images/h7.png'
                            />
                          </div>
                          <div className='wall__nft-item wall__nft-item--v8'>
                            <StaticImage
                                    alt='Origin Heroes'
                                    className="wall--nft"
                                    src='../images/8.png'
                            />
                          </div>
                        </div>
                      <StaticImage
                              alt='Origin Heroes'
                              className="wall--wall"
                              src='../images/st.png'
                      />
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='earth'>
            <StaticImage
                    alt='Origin Heroes'
                    className="earth__image"
                    src='../images/btwall.png'
            />
        </div>
        <div className='cloud'>
          <div className='cloud__container'>
            <div className='cloud__container-left'>
              <div 
                className='cloud__container-left-item cloud__container-left-item--v1'
                data-sal="slide-left"
                data-sal-delay="50"
                data-sal-duration="2000"
                data-sal-easing="ease">
                <StaticImage
                        alt='Origin Heroes'
                        className="earth__image"
                        src='../images/cloud_left1.png'
                />
              </div>
              <div 
                className='cloud__container-left-item cloud__container-left-item--v2'
                data-sal="slide-left"
                data-sal-delay="0"
                data-sal-duration="2000"
                data-sal-easing="ease">
                <StaticImage
                        alt='Origin Heroes'
                        className="earth__image"
                        src='../images/cloud_left2.png'
                />
              </div>
              <div 
                className='cloud__container-left-item cloud__container-left-item--v3'
                data-sal="slide-left"
                data-sal-delay="200"
                data-sal-duration="1500"
                data-sal-easing="ease">
                <StaticImage
                        alt='Origin Heroes'
                        className="earth__image"
                        src='../images/cloud_left3.png'
                />
              </div>
            </div>
            <div className='cloud__container-right'>
              <div 
                className='cloud__container-right-item cloud__container-right-item--v1'
                data-sal="slide-right"
                data-sal-delay="50"
                data-sal-duration="2000"
                data-sal-easing="ease">
                <StaticImage
                        alt='Origin Heroes'
                        className="earth__image"
                        src='../images/cloud_right1.png'
                />
              </div>
              <div 
                className='cloud__container-right-item cloud__container-right-item--v2'
                data-sal="slide-right"
                data-sal-delay="0"
                data-sal-duration="2000"
                data-sal-easing="ease">
                <StaticImage
                        alt='Origin Heroes'
                        className="earth__image"
                        src='../images/cloud_right2.png'
                />
              </div>
              <div 
                className='cloud__container-right-item cloud__container-right-item--v3'
                data-sal="slide-right"
                data-sal-delay="200"
                data-sal-duration="2000"
                data-sal-easing="ease">
                <StaticImage
                        alt='Origin Heroes'
                        className="earth__image"
                        src='../images/cloud_right3.png'
                />
              </div>
            </div>
          </div>
          <div className='etheria' id="etheria">
            <div className='etheria-container container'>
              <h2 className='etheria__title'>Welcome to Etheria</h2>
              <div className="etheria__video embed-responsive">
                    <iframe className="etheria__video-item embed-responsive-item" width="950" height="534" src={"https://www.youtube.com/embed/4muc01mqvsY?autoplay=0&mute=0&controls=1&modestbranding=0&rel=0"} title="Welcome to Etheria" frameBorder="0" muted allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
              <div className='etheria__row row relative' style={{zIndex:2}}>
                <div className='etheria__left col-md-5 col-12'>
                  <div className='dialog dialog--left'>
                    <h3>A land of magic and mystery.</h3>
                    <p>For thousands of years, its inhabitants of all races and backgrounds lived in peace and prosperity. Among the six major kingdoms - Everpine, Strathmore, Frostfall, Darkwell Landing, Portsmouth and New Grimsby, trade bloomed and conflict waned. As such, the need for warriors faltered. Armies became redundant and diminished over time, soon almost completely forgotten.</p>
                  </div>
                </div>
                <div className='etheria__left-image col-md-7 col-12'
                    data-sal="slide-left"
                    data-sal-delay="50"
                    data-sal-easing="ease">
                  <div className='mot'>
                    <StaticImage
                            alt='Origin Heroes'
                            className="etheria__left-image--static"
                            src='../images/eth1.png'
                    />
                  </div>
                </div>
              </div>
              <div className='etheria__row row relative' style={{zIndex:1}}>
                  <div className='etheria__right-image col-md-5 col-12'>
                    <StaticImage
                            alt='Origin Heroes'
                            className="etheria__right-image--dynamic hidemob"
                            src='../images/eth2.png'
                    />
                    <StaticImage
                            alt='Origin Heroes'
                            className="etheria__right-image--dynamic hidedesktop"
                            src='../images/eth2m.png'
                    />
                  </div>
                  <div className='etheria__right col-md-7 col-12'>
                    <div className='dialog dialog--right'>
                      <h3>Almost…</h3>
                      <p>As it has always come to pass, no peace lasts forever. A shadow swept across the land as quick as it was deadly. A shadow from which a great threat descended. Caught off guard, unprepared and desperate, the rulers of the six kingdoms reached desperately for the last glimmer of hope in the looming darkness<br/>
                      <b>– the Elixir of Courage.</b></p>
                      <p>
                        Remnants of what were once the best and strongest warriors were sent by each kingdom from all corners of the land. Their bodies then imbued with the powerful and mystical concoction. Steadily their numbers grew as the elixir was exhausted. One by one, until there were <b>8,888</b> of them. Thus, the <b>First Heroes Collective</b> was born.<br/><br/>
                        With it, the age of peace and prosperity ended, ushering in a new era of Heroes. An era born not of choice but of necessity to combat the approaching darkness.
                      </p>
                    </div>
                  </div>
                </div>
                    <StaticImage
                            alt='Origin Heroes'
                            className="etheria__right-image--back relative hidemob"
                            style={{zIndex:0}}
                            src='../images/eth3.png'
                    />
            </div>
          </div>
        </div>
        <div className='elixir'>
          <div className='elixir-container container'>
            <div className='elixir__row row'>
              <div className='elixir__left col-md-6 col-12 elixir__left--fix'>
                <div className='elixir__left-out'>
                  <h3 className='elixir__title'>Elixir of Courage</h3>
                  <p>
                    An otherworldly elixir that grants prolonged life, supernatural strength and even magical abilities. A concoction so powerful that even its creators, the inaugural House of Wizards, deemed it a threat. Thus, it was mandated in secret that all existing elixirs be destroyed, along with the intricate processes required to create them.<br/><br/>
                    <span className='hidemob'>The spells and formulas were promptly destroyed, lost forever, but before the existing elixir could meet the same fate, they were stolen by a clan of bandits, tipped off by a traitor within the order. For years, kingdoms rallied to recover the stolen elixirs. Much was found, but remnants remained scattered throughout the lands.<br/><br/>
                    Against the warnings of the House of Wizards, the recovered elixirs were split evenly amongst the kingdoms to be protected and stored within their treasuries.<br/><br/>
                    It comes as no surprise that the Elixir of Courage became a sought-after prize for warriors and warriors-to-be alike. More valuable than treasure and gold… a glimmer of hope.</span>
                  </p>
                </div>
              </div>
              <div className='elixir__right col-md-6 col-12'>
                <StaticImage
                        alt='Origin Heroes'
                        className="elixir__right-image--btl"
                        src='../images/btl2.png'
                />
                <StaticImage
                        alt='Origin Heroes'
                        className="elixir__right-image--static"
                        src='../images/fisthd.png'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='table'>
            <StaticImage
                    alt='Origin Heroes'
                    className="table_image"
                    src='../images/table.png'
            />
        </div>
        <div className='section--light relative'>
          <div className='hidedesktop precard'>
            <p className='precard__text'>The spells and formulas were promptly destroyed, lost forever, but before the existing elixir could meet the same fate, they were stolen by a clan of bandits, tipped off by a traitor within the order. For years, kingdoms rallied to recover the stolen elixirs. Much was found, but remnants remained scattered throughout the lands.<br/><br/>
                  Against the warnings of the House of Wizards, the recovered elixirs were split evenly amongst the kingdoms to be protected and stored within their treasuries.<br/><br/>
                  It comes as no surprise that the Elixir of Courage became a sought-after prize for warriors and warriors-to-be alike. More valuable than treasure and gold… a glimmer of hope.</p>
            <h3 className='precard__title'>Benefits of Heroes Origin NFT</h3>
          </div>
            {coins.map(coin => (
              <div
              key = {coin.id}
              className={'hidemob card__coin card__coin--v' + coin.id}
              style = {{top:coin.top, left: coin.left, transform: 'rotate(' + coin.rotate + ')'}}
              >
                  <StaticImage
                          alt='coin'
                          className="card__coin-image"
                          src='../images/coin.png'
                  />
              </div>
            ))}
          <div className='card' id="benefits">
            <div className='card-container container'>
            <h2 className='card__title hidemob'>Benefits</h2>
              <div className='card__row row'>
                {cards.map((card) => (
                  <div className='card__item-container col-md-4 col-12'
                  key = {card.id}
                  data-sal="flip-left"
                  data-sal-delay={card.id*100}
                  data-sal-easing="ease">
                    <FlipCard key={card.id} card={card} />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <img className='divider hidemob' src="/images/dark.svg" alt=""/>
          <StaticImage
                  alt='Origin Heroes'
                  className="card__gold hidemob"
                  src='../images/gold.png'
          />
        </div>
        <div id="roadmap" className='relative'> 
        <h3 className='roadmap__title hidemob'>Roadmap</h3>
        <div className='roadmap-out hidemob' style={{zoom: width}}>
          <div className='roadmap relative'>
                  <StaticImage
                          alt='Origin Heroes'
                          className="roadmap__image"
                          src='../images/Roadmap_5.png'
                  />
                  <div className='roadmap__add roadmap__add-or'>
                    <StaticImage
                            alt='Origin Heroes'
                            className="roadmap__add--image"
                            src='../images/orc.png'
                    />
                  </div>
                  <div className='roadmap__add roadmap__add-ch'>
                    <StaticImage
                            alt='Origin Heroes'
                            className="roadmap__add--image"
                            src='../images/rch.png'
                    />
                  </div>
                  <div className='roadmap__point-container'>
                      {roadmap.map((item) => (
                        <div className={'roadmap__point row roadmap__point--v' + item.id}
                        key = {item.id}
                        data-sal= "slide-right"
                        data-sal-delay="50"
                        data-sal-easing="ease">
                            <div className='roadmap__point-flag-container col-md-4'>
                              <StaticImage
                                      alt='Origin Heroes'
                                      className="roadmap__point-flag"
                                      src='../images/point.png'
                              />
                              <div className='roadmap__point-flag-txt'>
                                <h3>{item.quarter}</h3>
                                <p>{item.year}</p>
                              </div>
                            </div>
                            <div className='roadmap__point-content col-md-8'>
                              <ul>
                                {item.list.map((point) => (
                                  <li key={point.id}>{point}</li>
                                ))}
                              </ul>
                            </div>
                        </div>
                      ))}
                  </div>
          </div>
        </div>
            <img className='divider hidemob' src="/images/green.svg" alt=""/>
        </div>
        <div className='mobmap hidedesktop'>
          <h3 className='mobmap__title'>Roadmap</h3>
          <Swiper
            effect={"cards"}
            grabCursor={true}
            modules={[EffectCards, Pagination]}
            pagination={true}
            className="mySwiper"
          >
            {roadmap.map((item) => (
              <SwiperSlide className={'mobmap__point mobmap__point--v' + item.id} key = {'m' + item.id}>
                  <div className='mobmap__point-flag-container'>
                    <StaticImage
                            alt='Origin Heroes'
                            className="mobmap__point-flag"
                            src='../images/point.png'
                    />
                    <div className='mobmap__point-flag-txt'>
                      <h3>{item.quarter}</h3>
                      <p>{item.year}</p>
                    </div>
                  </div>
                  <div className='mobmap__point-content'>
                    <ul>
                      {item.list.map((point) => (
                        <li key={'mp' + point}>{point}</li>
                      ))}
                    </ul>
                  </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className='faq' id="faq">
          <div className='faq__container container'>
            <h2 className='faq__title'>FAQ</h2>
            <div className='faq__row row'>
              <div className='faq__left col-md-4 col-12'>
                <div className='faq__image-container'
                        data-sal= "zoom-in"
                        data-sal-delay="50"
                        data-sal-easing="ease">
                  <StaticImage
                          alt='Origin Heroes'
                          className="faq__image"
                          src='../images/sndk.png'
                          objectFit='contain'
                  />
                </div>
              </div>
              
              <div className='faq__right col-md-4 col-12'>
                <Faq data={data} getRowOptions={setRowsOption} config={config} />
              </div>
              <div className='faq__right col-md-4 col-12'>
                <Faq data={data2} getRowOptions={setRowsOption} config={config} />
              </div>
              
            </div>
          </div>
        </div>
        <div className='tastypill' id="tp">
            <img src="/images/tastypill.svg" alt=""/>
        </div>
        <div className='team' id="team">
          <div className='team__container container'>
            <h2 className='team__title'>Team</h2>
            <div className='team__row row'>
              {teams.map(team => (
                <Video
                id={'teamkey' + team.id}
                key={'teamkey' + team.id}
                video={{
                  url: team.img,
                  ogg: team.ogg,
                  webm: team.webm,
                  poster: team.poster,
                  id: 't' + team.id,
                  title: team.title,
                  poster: team.poster,
                  name: team.name,
                  descr: team.descr,
                  linkedin: team.linkedin,
                  twitter: team.twitter
                }}
                />
              ))}
            </div>
            <p className='team__plus'>+ many other talented team members</p>
          </div>
        </div>
        <div className='mountains-container'>
          <div className='mountains'>
            <StaticImage
                      alt='Origin Heroes'
                      className="mountains__image"
                      src='../images/mountains.png'
            />
          </div>
          <div className='mountains__text'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-6 col-12 fixleft'>
                  <p className="footer__date">© {new Date().getFullYear()} Tastypill. All rights reserved.</p>
                </div>
                <div className='col-md-6 col-12 fixright'>
                    <div className='fimg'>
                      <p>As seen on</p>
                      <a href="https://nftcalendar.io/">
                      <StaticImage
                                alt='Origin Heroes'
                                className="footer__nft"
                                src='../images/nft.png'
                                objectFit='contain'
                      />
                      </a>
                    </div>
                    <a href="https://punksvsapes.com/" className='ml20'>
                      <StaticImage
                                alt='Punks vs Apes'
                                className="footer__nft"
                                src='../images/pva.png'
                                objectFit='contain'
                                style={{maxWidth:'80px'}}
                      />
                    </a>
                    <a href="https://playl.ink/" className='ml20'>
                      <StaticImage
                                alt='Punks vs Apes'
                                className="footer__playlink"
                                src='../images/playlink.png'
                                objectFit='contain'
                                style={{maxWidth:'100px'}}
                      />
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>


      <svg xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision" width="700" height="400" className="hideme">
        <filter id="waterTexture">

        <feTurbulence result="undulation" numOctaves="1" baseFrequency="0" seed="0" type="turbulence">

          <animate attributeName="baseFrequency" dur="15s" keySplines="
              0.5 0 0.5 1;
              0.5 0 0.5 1" keyTimes="
              0;
              0.5;
              1" calcMode="spline" values="
              0.005,0.009; 
              0.009,0.005; 
              0.005,0.009" repeatCount="indefinite" />

        </feTurbulence>
        <feColorMatrix in="undulation" type="hueRotate" values="180">

          <animate attributeName="values" dur="1s" from="0" to="360" repeatCount="indefinite" />

        </feColorMatrix>
        <feColorMatrix in="dist" result="circulation" type="matrix" values="4 0 0 0 1
                                        4 0 0 0 1
                                        4 0 0 0 1
                                        1 0 0 0 0   
                                        " />
        <feDisplacementMap in="SourceGraphic" in2="circulation" scale="2" result="dist" />
        <feDisplacementMap in="dist" in2="undulation" scale="30" result="woah" />
      </filter>
    </svg>
    </Layout>
  )
}

export default EtheriaPage
