import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Music from '../audio/tavern2.mp3';
import useScrollPosition from "../components/useScrollPosition";

const HeaderH = () => {
  const [scrolled, setScrolled] = useState(false);


  
  const scrollPosition = useScrollPosition();
  
  const audioRef = useRef(typeof Audio !== "undefined" && new Audio(Music));

  const [playing, setPlaying] = useState(false);

  const play = () => {
    setPlaying(true);
    audioRef.current.play();
  };


  const pause = () => {
    setPlaying(false);
    audioRef.current.pause();
  };
  

  useEffect(() => {
    if(scrollPosition<800){
      audioRef.current.volume = 0.8 - (scrollPosition/1000)
    }
    else{
      audioRef.current.volume = 0;
    }
  }, [scrollPosition]);


  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled) {
        setScrolled(!scrolled);
      }
    };

    document.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled, setScrolled]);

  const [isActive, setActive] = useState(false);

  const handleToggle = () => {
    setActive(!isActive);
  };

  let menu = [
    {
      path: "#what",
      name: "What is Origin Heroes?",
      submenu: [],
      class: "nav__gallery",
    },
    {
      path: "#collection",
      name: "Collection",
      submenu: [],
      class: "nav_garden",
    },
    {
      path: "#etheria",
      name: "Etheria",
      submenu: [],
      class: "nav__careers",
    },
    {
      path: "#benefits",
      name: "Benefits",
      submenu: [],
      class: "nav__manifesto",
    },
    {
      path: "#roadmap",
      name: "Roadmap",
      submenu: [],
      class: "nav__mindmap",
    },
    {
      path: "#faq",
      name: "FAQ",
      submenu: [],
      class: "nav__faq",
    },
    {
      path: "#team",
      name: "Team",
      submenu: [],
      class: "nav__team",
    },
    {
      path: "https://originheroes.gitbook.io/origin-heroes/",
      name: "LITEPAPER",
      submenu: [],
      class: "nav__litepaper",
    },
  ];

  class Soc extends React.Component {
    render() {
      return (
      <ul className='soc__icons soc__icons--header'>
      <li className='soc__icons-title'>
      <a href='https://twitter.com/OriginHeroes'>
          <img
              alt='Twitter'
              className='soc__icons--image'
              src='../images/tw.svg'
          />
      </a>
      </li>
      <li className='soc__icons-title'>
      <a href='https://discord.gg/KRqbAKdcXY'>
          <img
              alt='Discord'
              className='soc__icons--image'
              src='../images/discord.svg'
          />
      </a>
      </li>
      <li className='soc__icons-title'>
      <a href='https://originheroes.gitbook.io/origin-heroes/'>
          <img
              alt='LITEPAPER'
              className='soc__icons--image'
              src='../images/lp.svg'
          />
      </a>
      </li>
  </ul>);
    }
  }

  return (
    
    <div>
    <div className='header header--homepage'>
      <div className='header__container container'>
        <Link
          className="header__leftside navbar-brand"
          to="#"
        >
          <StaticImage
              alt='Origin Heroes'
              objectFit='contain'
              className='logoback'
              src='../images/logo-back.png'
          />
          <StaticImage
              alt='Origin Heroes'
              objectFit='contain'
              className="header__leftside-logo"
              src='../images/logo.png'
          />
        </Link>
      </div>
    </div>
    <header className={scrolled?'hdr liquid-alt':'hdr liquid'}>
      <div className='hdr__container container'>
        <ul
          className={`${
            isActive
              ? "hdr__nav toggle nav active"
              : "hdr__nav toggle nav"
          }`}
        >
          {menu.map((link) => (
            <li
              className={
                link.submenu && link.submenu.length > 0
                  ? "hdr__nav-li nav-item hdr__submenu-parent"
                  : "hdr__nav-li nav-item"
              }
              key={link.name}
            >
              <a
                className={"hdr__nav-link nav-link headm " + link.class}
                href={link.path}
                bzz={link.name}
                onClick={handleToggle}
              >
                {link.name}
              </a>
              {link.submenu && link.submenu.length > 0 ? (
                <ul className="hdr__submenu">
                  {link.submenu.map((sublink) => (
                    <li className="hdr__submenu-item" key={sublink.name}>
                      <a
                        className={"hdr__nav-link nav-link " + sublink.class}
                        href={sublink.path}
                        onClick={handleToggle}
                      >
                        {sublink.name}
                      </a>
                    </li>
                  ))}
                </ul>
              ) : null}
            </li>
          ))}
          <li>
            <Soc/>
          </li>
        </ul>
        
        
        <div className='hero__play-fix'>
          <div className='hero__play-container'>
              <div className='hero__play--icon'>
                <img src="/images/music.svg"/>
              </div>
              <div className='between'></div>
              <button
                onClick={playing ? pause : play}
                className="hero__play"
              >
                <img src="/images/pause.svg" className={playing ?'hero__play--active img':'hero__play--disabled img'}/>
                <img src="/images/play.svg" className={!playing ?'hero__play--active img':'hero__play--disabled img'}/>
              </button>
          </div>
          <div className="header__soc">
            <Soc/>
          </div>
        </div>

        <button
          className={`${
            isActive
              ? "app hdr__menu toggle active"
              : " app hdr__menu toggle"
          }`}
          onClick={handleToggle}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </header>
    </div>
  );
};

export default HeaderH;
