import React, {useRef, useMemo } from "react";

const Video = ({ video }) => {
    const useVideo = (url) => {
        const videoRef = useRef();
    
        const handlers = useMemo(() => {  
            let videoLoaded;
            return {
            start() {
                if(!url || !videoRef.current) return;
            
                videoLoaded = videoRef.current.play()
            },
            pause() {
                if(!url || !videoRef.current) return;
            
                videoLoaded.then(() => {
                videoRef.current.pause();
                });
            }
            }
        }, [url]);
    
        return {
            videoRef,
            ...handlers
        };
    };
    
    const { start, pause, videoRef } = useVideo(video.url);

    return (
        <div
        onMouseOver={start}
        onMouseOut={pause}
        onFocus={start}
        onBlur={pause}
        className={'team__item col-md-3 col-12 t' + video.id}
        >
            <div className='team__card'>
                <div className='team__card-top'>
                    <video
                        ref={videoRef}
                        muted
                        height="100%"
                        width="100%"
                        loop
                        playsInline
                        poster={video.poster}
                        preload="none"
                    >
                        <source src={video.url} type="video/mp4"/>
                        <source src={video.webm} type="video/webm"/>
                    </video>
                </div>
                <div className='team__card-soc'>
                    <a style={{display: video.linkedin != "" ? "" : "none"}} href={video.linkedin}>
                        <img src="/images/ln.svg"/>
                    </a>
                    <a style={{display: video.twitter != "" ? "" : "none"}} href={video.twitter}>
                        <img src="/images/tw-2.svg"/>
                    </a>
                </div>
                <div className='team__card-bottom'>
                    <div className='team__card-bottom--left'>
                    <h4>{video.name}</h4>
                    <p className="team__card-bottom--title">{video.title}</p>
                    <p>{video.descr}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Video;