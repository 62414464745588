import React from 'react';

function FlipCard({ card: { id, icon, title, text } }) {

  return (
    <div className="flip-box"
    >
      <div className="flip-box-inner">
        <div className="flip-box-front">
          <img src={icon} alt="" className='flip-box-icon' />
          <h2>{title}</h2>
          <img src="/images/rotate.svg" alt="" className='flip-box-right'/>
        </div>
        <div className="flip-box-back">
          <p>{text}</p>
        </div>
      </div>
    </div>
  );
}

export default FlipCard;
